<template>
    <l-map
        ref="map"
        id="map-leaflet"
        :zoom="zoom"
        :center="center"
        :options="options"
        gestureHandling
    >
        <l-tile-layer
            :url="url"
            :attribution="attribution"
        />
        <!-- Use icon given in icon property -->
        <l-marker-cluster>
            <l-marker
                v-if="this.singleIcon"
                :lat-lng="this.defaultCenter"
                :icon="icon"
            >
            </l-marker>
            <l-marker
                v-else
                v-for="(group, index) in groupedMarkers"
                :key="'group-' + index"
                :lat-lng="group.lat_lng"
                :icon="icon"
                @click="flyTo(group.lat_lng)"
            >
                <l-popup class="popup">
                    <div class="listing-window-image-wrapper">
                        <div class="slideshow" :id="'group-' + index" ref="markersPlaceholder">
                            <div class="propriete"
                                 :class="{ 'active': itemIndex === 0 }" v-for="(product, itemIndex) in group.items"
                                 :key="'item-' + itemIndex">
                                <a :href="productUrl(product)" class="listing-window-content">
                                    <div class="listing-window-image"
                                         :style="'background-image: url(' + baseImage(product) + ');'">
                                        <h3 v-text="getPrice(product).formatted_price"></h3>
                                    </div>
                                    <div class="pb-2">
                                        <div class="info">
                                            <h2 v-text="product.name"></h2>
                                            <p>
                                                <span v-html="product.categories[0].name"></span>
                                                -
                                                <span v-html="product.address"></span>
                                            </p>
                                        </div>
                                    </div>
                                </a>
                                <div class="cycle pb-2" v-if="group.items.length > 1">
                                    <a href="#" class="prev"><i class="fas fa-angle-double-left"></i></a>
                                    <span class="total" v-text="itemIndex+1 + '/' + group.items.length"></span>
                                    <a href="#" class="next"><i class="fas fa-angle-double-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </l-popup>
            </l-marker>
        </l-marker-cluster>
    </l-map>
</template>


<script>
import { divIcon } from 'leaflet';
import 'leaflet-gesture-handling';
import { LControlZoom, LIcon, LMap, LMarker, LPopup, LTileLayer, LTooltip } from 'vue2-leaflet';
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster';

export default {
    props: {
        markers: {
            type: Array,
            default: ()=>[],
        },
        defaultCenter: {
            type: Array,
            default: ()=> FleetCart.storeLatLng.split(','),
        },
        singleIcon: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        LMap,
        LTileLayer,
        LMarker,
        LPopup,
        LTooltip,
        LIcon,
        'LMarkerCluster': Vue2LeafletMarkerCluster,
        LControlZoom,
    },
    data() {
        return {
            zoom: 14,
            maxZoom: 18,
            tap: false,
            center: this.defaultCenter,
            url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            attribution:
                '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
            icon: divIcon({
                html: '<i class="fa fa-home"></i>',
                iconSize: [50, 50],
                iconAnchor: [25, 57],
                popupAnchor: [0, -60],
            }),
            options: {
                scrollWheelZoom:false,
                gestureHandling: true,
                zoomSnap: 0.5,
            },
        };
    },
    computed: {
        groupedMarkers() {
            const groupedData = {};
            this.markers.forEach(item => {
                const latLngKey = item.lat_lng.toString();
                if (! groupedData.hasOwnProperty(latLngKey)) {
                    groupedData[latLngKey] = {
                        lat_lng: item.lat_lng,
                        items: [item],
                    };
                } else {
                    groupedData[latLngKey].items.push(item);
                }
            });
            return Object.values(groupedData);
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.slickInitialized();
            this.map = this.$refs.map.mapObject;
        });
    },
    methods: {
        getPrice(product) {
            return {
                ...(product.variant ? product.variant : product),
            };
        },
        slickInitialized() {
            $('#map-leaflet').on('click', '.popup .cycle a', function () {
                let $slideshowWrapper = $(this).closest('.listing-window-image-wrapper'),
                    $slideshow = $slideshowWrapper.find('.slideshow'),
                    $activeSlide = $slideshow.find('.active'),
                    $newSlide;

                if ($(this).hasClass('prev')) {
                    $newSlide = $activeSlide.prev();
                    if ($newSlide.length === 0) {
                        $newSlide = $slideshow.find('.propriete').last();
                    }
                } else {
                    $newSlide = $activeSlide.next();
                    if ($newSlide.length === 0) {
                        $newSlide = $slideshow.find('.propriete').first();
                    }
                }

                $activeSlide.removeClass('active').hide();
                $newSlide.addClass('active').show();
                return false;
            });
        },
        productUrl(item) {
            return route('products.show', {
                slug: item.slug,
                ...(this.hasAnyVariant && {
                    variant: this.item.uid,
                }),
            });
        },
        hasBaseImage(item) {
            return item.base_image.length !== 0;
        },

        hasVarianteBaseImage(item) {
            return item.base_image.length !== 0;
        },
        baseImage(item) {
            if (this.hasVarianteBaseImage(item)) {
                return item.base_image.path;
            } else if (this.hasBaseImage(item)) {
                return item.base_image.path;
            }
            return `${window.FleetCart.baseUrl}/build/assets/image-placeholder.jpg`;
        },
        flyTo(latLng) {
            this.map.flyTo(latLng);
        },
    },
};
</script>
