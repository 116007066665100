import moment from 'moment';

export default {
    computed: {
        postUrl() {
            return route('pages.show', this.post.slug);
        },

        hasBannerImage() {
            return this.post.banner.length !== 0;
        },

        bannerImage() {
            if (this.hasBannerImage) {
                return this.post.banner.path;
            }

            return `${window.FleetCart.baseUrl}/build/assets/image-placeholder.jpg`;
        },

        dateAndAuthor() {
            return this.datePost + ' | ' + this.authorFullName;
        },

        datePost() {
            return moment(this.post.created_at).locale(FleetCart.locale).format('ll');
        },

        authorFullName() {
            return this.post.author.first_name + ' ' + this.post.author.last_name;
        },
    },
};
