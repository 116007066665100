<template>
    <section class="featured-category similar-property featured portfolio bg-white-inner">
        <div class="container">
            <div class="">
                <div class="sec-title" data-aos="zoom-in" data-aos-delay="50">
                    <h2 class="title">{{ data.title }}</h2>
                    <span class="excerpt" v-html="data.subtitle"></span>
                </div>

                <ul class="homes-list">
                    <li
                        v-for="(tab, index) in tabs"
                        :key="index"
                        :class="classes(tab)"
                        @click="change(tab)"
                    >
                        <div class="featured-category-image" v-if="tab.hasLogo">
                            <img
                                :src="tab.logo"
                                :class="{ 'image-placeholder': !tab.hasLogo }"
                                alt="Category logo"
                            />
                        </div>

                        <span class="featured-category-name" v-html="makeLogo(tab.label)"
                              data-aos="zoom-in" :data-aos-delay="150+(index*200)"></span>
                    </li>
                </ul>
            </div>

            <div class="featured-portfolio-items row portfolio-items" data-aos="fade-up" data-aos-delay="250">
                <ProductCard
                    v-for="product in products"
                    :key="product.id"
                    :product="product"
                    class="item col-12"
                />
            </div>
        </div>

        <dynamic-tab
            v-for="(category, index) in data.categories"
            :key="index"
            :label="category.name"
            :initial-logo="category.logo"
            :url="
                route('storefront.featured_category_products.index', {
                    categoryNumber: index + 1,
                })
            "
        >
        </dynamic-tab>
    </section>
</template>

<script>
import ProductCard from '../ProductCard.vue';
import DynamicTabsMixin from '../../mixins/DynamicTabsMixin';

export default {
    components: { ProductCard },

    mixins: [DynamicTabsMixin],

    props: ['data'],

    methods: {
        makeLogo(label) {
            let labels = label.split(' ');
            return labels[0] + '<sup>' + labels[1] + '</sup>';
        },
        selector() {
            return $('.featured-portfolio-items');
        },

        slickOptions() {
            return {
                rows: 0,
                dots: true,
                arrows: true,
                infinite: true,
                slidesToShow: 3,
                slidesToScroll: 3,
                responsive: [
                    {
                        breakpoint: 1025,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                        },
                    },
                    {
                        breakpoint: 577,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                        },
                    },
                ],
            };
        },
    },
};
</script>
