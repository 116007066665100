import {trans} from '../../functions';

export default {
    components: { VPagination: () => import('../VPagination.vue') },

    props: [
        'initialQuery',
        'initialCategoryName',
        'initialCategoryBanner',
        'initialCategorySlug',
        'initialArchive',
        'initialTagName',
        'initialTagSlug',
        'initialPerPage',
        'initialPage',
    ],

    data() {
        return {
            fetchingPosts: false,
            posts: { data: [] },
            categoryName: this.initialCategoryName,
            archivePeriod: this.initialArchive,
            categoryBanner: this.initialCategoryBanner,
            queryParams: {
                query: this.initialQuery,
                category: this.initialCategorySlug,
                tag: this.initialTagSlug,
                archive: this.initialArchive,
                perPage: this.initialPerPage,
                page: this.initialPage,
            },
        };
    },

    computed: {
        emptyPosts() {
            return this.posts.data.length === 0;
        },

        totalPage() {
            return Math.ceil(this.posts.total / this.queryParams.perPage);
        },

        showingResults() {
            if (this.emptyPosts) {
                return;
            }

            return trans('storefront::products.showing_results', {
                from: this.posts.from,
                to: this.posts.to,
                total: this.posts.total,
            });
        },
    },

    mounted() {
        this.addEventListeners();
        this.fetchPosts();
    },

    methods: {
        addEventListeners() {
            $(this.$refs.perPageSelect).on('change', (e) => {
                this.queryParams.perPage = e.currentTarget.value;

                this.fetchPosts();
            });
        },

        changeArchive(archive) {
            if (archive) {
                this.archivePeriod = archive;
                this.queryParams.archive = archive;
            } else {
                this.archivePeriod = null;
                this.queryParams.archive = null;
            }
            this.queryParams.query = null;
            this.queryParams.page = 1;

            this.fetchPosts();
        },

        changeCategory(category) {
            if (category) {
                this.categoryName = category.name;
                this.categoryBanner = category.banner.path;
                this.queryParams.category = category.slug;
            } else {
                this.categoryName = null;
                this.categoryBanner = null;
                this.queryParams.category = null;
            }
            this.queryParams.query = null;
            this.queryParams.page = 1;

            this.fetchPosts();
        },

        changePage(page) {
            this.queryParams.page = page;

            this.fetchPosts();
        },

        async fetchPosts(options = { updateAttributeFilters: true }) {
            this.fetchingPosts = true;

            try {
                const response = await axios.get(
                    route('posts.index', this.queryParams)
                );

                this.posts = response.data.posts;

                if (options.updateAttributeFilters) {
                    this.attributeFilters = response.data.attributes;
                }
            } catch (error) {
                this.$notify(error.response.data.message);
            } finally {
                this.fetchingPosts = false;
            }
        },
    },
};
