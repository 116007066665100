<template>
    <div class="recent-main">
        <div class="recent-img">
            <a :href="productUrl">
                <img
                    :src="baseImage"
                    :class="{ 'image-placeholder': !hasBaseImage }"
                    :alt="product.name"
                />
            </a>
        </div>
        <div class="info-img">
            <a :href="productUrl">
                <h6>{{ product.name }}</h6>
            </a>
            <p class="m-0" v-html="item.formatted_price"></p>
            <product-rating
                :ratingPercent="product.rating_percent"
                :reviewCount="product.reviews.length"
                v-if="reviewsEnabled"
            >
            </product-rating>
        </div>
    </div>
</template>

<script>
import ProductCardMixin from '../mixins/ProductCardMixin';

export default {
    data() {
        return {
            reviewsEnabled: FleetCart.reviewsEnabled,
        };
    },
    
    mixins: [ProductCardMixin],

    props: ['product'],

    computed: {
        item() {
            return {
                ...(this.product.variant ? this.product.variant : this.product),
            };
        },
    },
};
</script>
