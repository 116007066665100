<template>
    <!-- START SECTION WHY CHOOSE US -->
    <section class="how-it-works bg-white">
        <div class="container">
            <div class="sec-title">
                <h2 data-aos="zoom-in" data-aos-delay="150">{{ data.title }}</h2>
                <span data-aos="fade-up" data-aos-delay="250" v-html="data.subtitle"></span>
            </div>
            <div class="row service-1">
                <article class="col-lg-4 col-md-6 col-xs-12 serv" data-aos="fade-up" data-aos-delay="650">
                    <div class="serv-flex">
                        <div class="art-1 img-13">
                            <img :src="data.banners.banner_1.image.path" :alt="data.banners.banner_1.call_to_action_title" />
                            <a
                                :href="data.banners.banner_1.call_to_action_url"
                                class="banner"
                                :target="
                            data.banners.banner_1.open_in_new_window
                                ? '_blank'
                                : '_self'"
                            >
                                <h3>{{ data.banners.banner_1.call_to_action_title }}</h3>
                            </a>
                        </div>
                        <div class="service-text-p">
                            <span v-html="data.banners.banner_1.call_to_action_body"></span>
                        </div>
                    </div>
                </article>
                <article class="col-lg-4 col-md-6 col-xs-12 serv" data-aos="fade-up" data-aos-delay="850">
                    <div class="serv-flex">
                        <div class="art-1 img-13">
                            <img :src="data.banners.banner_2.image.path" :alt="data.banners.banner_2.call_to_action_title" />
                            <a
                                :href="data.banners.banner_2.call_to_action_url"
                                class="banner"
                                :target="
                            data.banners.banner_2.open_in_new_window
                                ? '_blank'
                                : '_self'"
                            >
                                <h3>{{ data.banners.banner_2.call_to_action_title }}</h3>
                            </a>
                        </div>
                        <div class="service-text-p">
                            <span v-html="data.banners.banner_2.call_to_action_body"></span>
                        </div>
                    </div>
                </article>
                <article class="col-lg-4 col-md-6 col-xs-12 serv mb-0 pt" data-aos="fade-up" data-aos-delay="950">
                    <div class="serv-flex">
                        <div class="art-1 img-13">
                            <img :src="data.banners.banner_3.image.path" :alt="data.banners.banner_3.call_to_action_title" />
                            <a
                                :href="data.banners.banner_3.call_to_action_url"
                                class="banner"
                                :target="
                            data.banners.banner_3.open_in_new_window
                                ? '_blank'
                                : '_self'"
                            >
                                <h3>{{ data.banners.banner_3.call_to_action_title }}</h3>
                            </a>
                        </div>
                        <div class="service-text-p">
                            <span v-html="data.banners.banner_3.call_to_action_body"></span>
                        </div>
                    </div>
                </article>
            </div>
        </div>
    </section>
    <!-- END SECTION WHY CHOOSE US -->
<!--    <section class="banner-wrap three-column-banner">-->
<!--        <div class="container">-->
<!--            <div class="row">-->
<!--                <div class="col-md-6">-->
<!--                </div>-->

<!--                <div class="col-md-6">-->
<!--                    <a-->
<!--                        :href="data.banner_2.call_to_action_url"-->
<!--                        class="banner"-->
<!--                        :target="-->
<!--                            data.banner_2.open_in_new_window-->
<!--                                ? '_blank'-->
<!--                                : '_self'-->
<!--                        "-->
<!--                    >-->
<!--                        <img :src="data.banner_2.image.path" alt="Banner" />-->
<!--                    </a>-->
<!--                </div>-->

<!--                <div class="col-md-6">-->
<!--                    <a-->
<!--                        :href="data.banner_3.call_to_action_url"-->
<!--                        class="banner"-->
<!--                        :target="-->
<!--                            data.banner_3.open_in_new_window-->
<!--                                ? '_blank'-->
<!--                                : '_self'-->
<!--                        "-->
<!--                    >-->
<!--                        <img :src="data.banner_3.image.path" alt="Banner" />-->
<!--                    </a>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </section>-->
</template>

<script>
export default {
    props: ["data"],
};
</script>
