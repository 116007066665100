<template>
    <div class="header-search-wrap">
        <form class="row justify-content-around align-items-center" @submit.prevent="search">
<!--            <div class="rld-single-input col-4">-->
<!--                <input-->
<!--                    type="text"-->
<!--                    name="query"-->
<!--                    class="pt-0 pb-0"-->
<!--                    autocomplete="off"-->
<!--                    v-model="form.query"-->
<!--                    :placeholder="$trans('storefront::layout.search_for_products')"-->
<!--                />-->
<!--            </div>-->
            <div class="rld-single-select col-12 col-md">
                <select
                    name="category"
                    class="header-search-select custom-select-option single-select"
                    @nice-select-updated="changeCategory($event.target.value)"
                >
                    <option
                        value=""
                    >
                        {{ $trans("storefront::layout.categories") }}
                    </option>

                    <option
                        v-for="category in categories"
                        :key="category.slug"
                        :value="category.slug"
                        :selected="category.slug === initialCategory"
                    >
                        {{ category.name }}
                    </option>
                </select>
            </div>

            <div class="rld-single-select col-12 col-md" v-for="attribute in attributeFilters" :key="attribute.id" v-cloak>
                <select
                    :name="attribute.slug"
                    class="header-search-select custom-select-option single-select"
                    @nice-select-updated="changeAttribute(attribute.slug, $event.target.value)"
                >
                    <option
                        value=""
                    >
                        {{ attribute.name }}
                    </option>

                    <option
                        v-for="value in attribute.values" :key="value.id"
                        :value="value.value"
                        :selected="attribute.slug === value.value"
                    >
                        {{ value.value }}
                    </option>
                </select>
            </div>
            <div class="rld-single-select col-12 col-md">
                <button
                    type="submit"
                    class="btn btn-yellow"
                >
                    {{ $trans('storefront::layout.search')}}
                </button>
            </div>
        </form>
    </div>
</template>

<script>
export default {
    props: [
        'categories',
        'attributeFilters',
        'mostSearchedKeywords',
        'isMostSearchedKeywordsEnabled',
        'initialQuery',
        'initialCategory',
        'initialAttribute',
    ],

    data() {
        return {
            activeSuggestion: null,
            showSuggestions: false,
            form: {
                query: this.initialQuery,
                category: this.initialCategory,
                attribute: {},
            },
        };
    },

    methods: {
        changeCategory(category) {
            this.form.category = category;
        },

        search() {
            // if (! this.form.query) {
            //     return;
            // }

            if (this.form.category) {
                window.location.href = route(
                    'categories.products.index',
                    this.form
                );

                return;
            }

            window.location.href = route('products.index', this.form);
        },

        changeAttribute(slug, value) {
            if (! this.form.attribute.hasOwnProperty(slug)) {
                this.form.attribute[slug] = [];
            }

            if (this.form.attribute[slug].includes(value)) {
                this.form.attribute[slug].splice(
                    this.form.attribute[slug].indexOf(value),
                    1
                );
            } else {
                this.form.attribute[slug].push(value);
            }
        },
    },
};
</script>
