<template>
    <div>
        <div class="project-single">
            <div class="project-inner project-head">
                <div class="project-bottom">
                    <h4>
                        <a :href="productUrl">{{ $trans('storefront::product_card.view_property') }}</a>
                        <span class="category">{{ product.categories[0].name }}</span>
                    </h4>
                </div>
                <div class="homes">
                    <!-- homes img -->
                    <a :href="productUrl" class="homes-img">
                        <div class="homes-tag button alt sale" v-if="item.is_out_of_stock">
                            {{ $trans("storefront::product_card.out_of_stock") }}
                        </div>
                        <div class="homes-tag button alt featured" v-if="product.is_new">
                            {{ $trans("storefront::product_card.new") }}
                        </div>
<!--                        <div class="homes-price" v-if="item.has_percentage_special_price">-->
<!--                            {{ item.special_price_percent }}%-->
<!--                        </div>-->
                        <img :src="baseImage"
                             :class="{ 'image-placeholder': !hasBaseImage }"
                             :alt="product.name" class="img-responsive">
                    </a>
                </div>
                <div class="button-effect">
                    <a v-if="product.youtube_url" :href="product.youtube_url" class="popup-video popup-youtube"><i
                        class="fas fa-video"></i></a>
                </div>
            </div>
            <!-- homes content -->
            <div class="homes-content">
                <!-- homes address -->
                <h3><a :href="productUrl">{{ product.name }}</a></h3>
                <p class="homes-address mb-3">
                    <a :href="productUrl">
                        <i class="fa fa-map-marker"></i><span>{{ product.address }}</span>
                    </a>
                </p>
                <p class="product-short-description" v-html="product.short_description">
                </p>
                <!-- homes List -->
                <ul class="homes-list clearfix pb-3">
                    <li class="the-icons" v-for="attribute in filteredAttributes" :key="attribute.attribute.id">
                        <template v-for="value in attribute.values">
                            <i class="mr-2" :class="attribute.attribute.slug" aria-hidden="true"></i>
                            <span>{{ value.value }} {{ attribute.attribute.name }}</span>
                        </template>
                    </li>
                    <li class="the-icons" v-if="product.surface_area > 0">
                        <i class="flaticon-square mr-2" aria-hidden="true"></i>
                        <span>{{ product.surface_area }} <span
                            v-html="$trans('storefront::product_card.square_feet')"></span></span>
                    </li>
                    <!--                <li class="the-icons">-->
                    <!--                    <i class="flaticon-bathtub mr-2" aria-hidden="true"></i>-->
                    <!--                    <span>3 Bathrooms</span>-->
                    <!--                </li>-->
                    <!--                <li class="the-icons">-->
                    <!--                    <i class="flaticon-car mr-2" aria-hidden="true"></i>-->
                    <!--                    <span>2 Garages</span>-->
                    <!--                </li>-->
                </ul>
                <!-- Price -->
                <div class="price-properties">
                    <h3 class="title mt-3">
                        <a :href="productUrl" v-html="item.formatted_price"></a>
                    </h3>
<!--                    <div class="compare">-->
<!--                        <a href="javascript:void(0)" title="Compare"-->
<!--                           :class="{ added: inCompareList }"-->
<!--                           :title="$trans('storefront::product_card.compare')"-->
<!--                           @click="syncCompareList"-->
<!--                        >-->
<!--                            <i class="fas fa-exchange-alt"></i>-->
<!--                        </a>-->
<!--                        &lt;!&ndash;                    <a href="#" title="Share">&ndash;&gt;-->
<!--                        &lt;!&ndash;                        <i class="fas fa-share-alt"></i>&ndash;&gt;-->
<!--                        &lt;!&ndash;                    </a>&ndash;&gt;-->
<!--                        <a href="javascript:void(0)" title="Favorites" :class="{ added: inWishlist }"-->
<!--                           :title="$trans('storefront::product_card.wishlist')"-->
<!--                           @click="syncWishlist">-->
<!--                            <i class="fa" :class="inWishlist ? 'fa-heart' : 'fa-heart-o'"></i>-->
<!--                        </a>-->
<!--                    </div>-->
                </div>
                <div class="footer">
                    <product-rating
                        :ratingPercent="product.rating_percent"
                        :reviewCount="product.reviews.length"
                        v-if="reviewsEnabled"
                    >
                    </product-rating>
                </div>
            </div>
        </div>
    </div>

    <!--    <div class="product-card">-->
    <!--        <div class="product-card-top">-->

    <!--            <div class="product-card-actions">-->

    <!--                <button-->
    <!--                    class="btn btn-compare"-->
    <!--                    -->
    <!--                    -->
    <!--                >-->
    <!--                    <i class="las la-random"></i>-->
    <!--                </button>-->
    <!--            </div>-->

    <!--            <ul class="list-inline product-badge">-->
    <!--                <li class="badge badge-danger">-->
    <!--                    -->
    <!--                </li>-->

    <!--                <li class="badge badge-primary">-->
    <!--                    -->
    <!--                </li>-->

    <!--                <li-->
    <!--                    class="badge badge-success"-->
    <!--                    -->
    <!--                >-->
    <!--                    --->
    <!--                </li>-->
    <!--            </ul>-->
    <!--        </div>-->

    <!--        <div class="product-card-middle">-->
    <!--            <product-rating-->
    <!--                :ratingPercent="product.rating_percent"-->
    <!--                :reviewCount="product.reviews.length"-->
    <!--            >-->
    <!--            </product-rating>-->

    <!--            <a :href="productUrl" class="product-name">-->
    <!--                <h6></h6>-->
    <!--            </a>-->

    <!--            <div-->
    <!--                class="product-price product-price-clone"-->
    <!--                -->
    <!--            ></div>-->
    <!--        </div>-->

    <!--        <div class="product-card-bottom">-->
    <!--            <div class="product-price" v-html="item.formatted_price"></div>-->

    <!--            <button-->
    <!--                v-if="hasNoOption || item.is_out_of_stock"-->
    <!--                class="btn btn-primary btn-add-to-cart"-->
    <!--                :class="{ 'btn-loading': addingToCart }"-->
    <!--                :disabled="item.is_out_of_stock"-->
    <!--                @click="addToCart"-->
    <!--            >-->
    <!--                <i class="las la-cart-arrow-down"></i>-->
    <!--                {{ $trans("storefront::product_card.add_to_cart") }}-->
    <!--            </button>-->

    <!--            <a-->
    <!--                v-else-->
    <!--                :href="productUrl"-->
    <!--                class="btn btn-primary btn-add-to-cart"-->
    <!--            >-->
    <!--                <i class="las la-eye"></i>-->
    <!--                {{ $trans("storefront::product_card.view_options") }}-->
    <!--            </a>-->
    <!--        </div>-->
    <!--    </div>-->
</template>

<script>
import ProductCardMixin from '../mixins/ProductCardMixin';

export default {
    data() {
        return {
            reviewsEnabled: FleetCart.reviewsEnabled,
        };
    },

    mixins: [ProductCardMixin],

    props: ['product'],

    mounted() {
        $('.popup-youtube, .popup-vimeo, .popup-gmaps').magnificPopup({
            disableOn: 700,
            type: 'iframe',
            mainClass: 'mfp-fade',
            removalDelay: 160,
            preloader: false,
            fixedContentPos: false,
        });
    },
    computed: {
        item() {
            return {
                ...(this.product.variant ? this.product.variant : this.product),
            };
        },
        hasAnyAttribute() {
            return this.product.attributes.length; // Assurez-vous que cette méthode existe dans votre modèle de produit
        },
        filteredAttributes() {
            // Filtrer les attributs selon les IDs spécifiés
            return this.product.attributes.filter(attribute => [6, 7].includes(attribute.attribute_id));
        },
    },
};
</script>
