<template>
    <!-- START SECTION COUNTER UP -->
    <section class="counterup">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-6 col-10" v-for="(feature, index) in features" :key="index" data-aos="flip-down" :data-aos-delay="250+index*300">
                    <div class="countr" v-if="feature.title">
                        <i class="pb-4" :class="feature.icon"></i>
                        <div class="count-me" v-html="getContent(feature.title, feature.subtitle)">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import _ from 'lodash';

export default {
    props: ['features'],
    mounted() {
        this.initCounter();
    },
    methods: {
        getContent(title, subtitle) {
            if (_.isInteger(parseInt(title))) {
                title = '<p class="counter">' + title + '</p>';
            } else {
                title = '<p>' + title + '</p>';
            }
            return title + '<h3>' + subtitle + '</h3>';
        },
        initCounter() {
            $('.counter').counterUp({
                delay: 10,
                time: 5000,
                offset: 100,
                beginAt: 0,
                formatter: function (n) {
                    return n.replace(/,/g, '.');
                },
            });
        },
    },
};
</script>
