<template>
    <section class="services-home info-help h17" :style="'background-image:url(' + banner.image.path + ')'">
        <div class="container">
            <div class="row info-head">
                <div class="col-md-8 col-xs-8 aos-init aos-animate m-auto" data-aos="fade-right">
                    <div class="info-text text-center">
                        <h3>{{ banner.call_to_action_title }}</h3>
                        <span class="body-banner" v-html="banner.call_to_action_body"></span>
                        <div class="inf-btn pro" v-if="banner.call_to_action_url">
                            <a :href="banner.call_to_action_url" class="btn btn-pro btn-secondary btn-lg"
                               :target="banner.open_in_new_window ? '_blank' : '_self'">{{ $trans('storefront::layout.learn_more') }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    props: ["banner"],
};
</script>
