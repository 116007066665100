<template>
    <div class="col-md-6 col-xs-12 pb-3 pl-0">
        <div class="news-item nomb">
            <a :href="postUrl" class="news-img-link">
                <div class="news-item-img">
                    <img class="img-responsive" :class="{ 'image-placeholder': ! hasBannerImage }" :src="bannerImage" alt="Post image">
                </div>
            </a>
            <div class="news-item-text">
                <a :href="postUrl">
                    <h3>{{ post.name }}</h3>
                </a>
                <div class="dates">
                    <span class="date" v-text="datePost"></span>
                    <ul class="action-list pl-0">
                        <li class="action-item">
                            | &nbsp;<i class="fa fa-eye"></i>&nbsp;
                            <span v-text="post.viewed"></span>
                        </li>
                    </ul>
                </div>
                <div class="news-item-descr big-news">
                    <p v-html="post.short_description"></p>
                </div>
                <div class="news-item-bottom">
                    <a :href="postUrl" class="news-link">{{ $trans('storefront::post_card.read_more') }}</a>
                    <div class="admin">
                        <p v-text="authorFullName"></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import PostCardMixin from '../../mixins/PostCardMixin';

export default {

    mixins: [
        PostCardMixin,
    ],

    props: ['post'],
};
</script>
