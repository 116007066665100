<template>
    <ProductCard :product="product" class="item col-12 col-md-6 border-0"/>
</template>

<script>
import ProductCard from './../../ProductCard.vue';

export default {
    components: { ProductCard },

    props: ['product'],
};
</script>
