import store from "../../store";
import Errors from "../../Errors";
import GalleryMixin from "./show/mixins/GalleryMixin";
import VariationMixin from "./show/mixins/VariationMixin";
import VariantMixin from "./show/mixins/VariantMixin";
import OptionMixin from "./show/mixins/OptionMixin";
import QuantityMixin from "./show/mixins/QuantityMixin";
import ReviewMixin from "./show/mixins/ReviewMixin";
import ProductCardMixin from "../../mixins/ProductCardMixin";

export default {
    components: {
        VPagination: () => import("../VPagination.vue"),
        RelatedProducts: () => import("./show/RelatedProducts.vue"),
    },

    mixins: [
        GalleryMixin,
        VariationMixin,
        VariantMixin,
        OptionMixin,
        QuantityMixin,
        ReviewMixin,
        ProductCardMixin,
    ],

    props: ["product", "variant", "reviewCount", "avgRating"],

    data() {
        return {
            item: {
                ...(this.product.variant ? this.variant : this.product),
            },
            oldMediaLength: null,
            activeVariationValues: {},
            variationImagePath: null,
            price: this.product.formatted_price,
            activeTab: "description",
            currentReviewPage: 1,
            fetchingReviews: false,
            reviews: {},
            addingNewReview: false,
            reviewForm: {},
            cartItemForm: {
                product_id: this.product.id,
                qty: 1,
                variations: {},
                options: {},
            },
            errors: new Errors(),
        };
    },

    computed: {
        productUrl() {
            return route("products.show", {
                slug: this.product.slug,
                ...(this.hasAnyVariant && {
                    variant: this.item.uid,
                }),
            });
        },

        isActiveItem() {
            return this.item.is_active === true;
        },

        isAddToCartDisabled() {
            return this.item.is_out_of_stock ?? true;
        },
    },

    mounted() {
        new Swiper('.swiper-container', {
            slidesPerView: 3,
            slidesPerGroup: 1,
            loop: true,
            loopFillGroupWithBlank: true,
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            breakpoints: {
                640: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                768: {
                    slidesPerView: 1,
                    spaceBetween: 40,
                },
            }
        });

        $('.popup-youtube, .popup-vimeo, .popup-gmaps').magnificPopup({
            disableOn: 700,
            type: 'iframe',
            mainClass: 'mfp-fade',
            removalDelay: 160,
            preloader: false,
            fixedContentPos: false
        });

        // $('.slick-carousel').each(function() {
        //     var slider = $(this);
        //     $(this).slick({
        //         infinite: true,
        //         dots: false,
        //         arrows: false,
        //         centerMode: true,
        //         centerPadding: '0'
        //     });
        //
        //     $(this).closest('.slick-slider-area').find('.slick-prev').on("click", function() {
        //         slider.slick('slickPrev');
        //     });
        //     $(this).closest('.slick-slider-area').find('.slick-next').on("click", function() {
        //         slider.slick('slickNext');
        //     });
        // });
    },
    created() {
        if (this.hasAnyVariant && !this.item.is_active) {
            this.setInactiveItemData();
        }
    },

    methods: {
        setInactiveItemData() {
            this.item = {
                uid: this.item.uid,
                media: [],
                base_image: [],
            };
        },

        isMobileDevice() {
            return window.matchMedia("only screen and (max-width: 991px)")
                .matches;
        },

        addToCart() {
            if (this.isAddToCartDisabled) return;

            this.addingToCart = true;

            axios
                .post(route("cart.items.store"), {
                    ...this.cartItemForm,
                    ...(this.hasAnyVariant && { variant_id: this.item.id }),
                })
                .then((response) => {
                    store.updateCart(response.data);

                    $(".header-cart").trigger("click");
                })
                .catch(({ response }) => {
                    this.errors.record(response.data.errors);

                    this.$notify(response.data.message);
                })
                .finally(() => {
                    this.addingToCart = false;
                });
        },

        initUpSellProductsSlider() {
            $(this.$refs.upSellProducts).slick({
                rows: 0,
                dots: false,
                arrows: true,
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                rtl: window.FleetCart.rtl,
            });
        },
    },
};
