import store from "../../store";
import CartHelpersMixin from "../../mixins/CartHelpersMixin";
import CartItemHelpersMixin from "../../mixins/CartItemHelpersMixin";
import Errors from '../../Errors';

export default {
    mixins: [CartHelpersMixin, CartItemHelpersMixin],

    props: ['countries'],

    data() {
        return {
            shippingMethodName: null,
            crossSellProducts: [],
            form: {
                shipping: {},
                cart: {},
            },
            states: {
                shipping: {},
            },
            errors: new Errors(),
        };
    },

    watch: {
        'form.shipping.zip': function (newZip) {
            if (newZip) {
                this.loadingOrderSummary = true;
                this.getRates();
            }
        },
    },

    computed: {
        hasAnyCrossSellProduct() {
            return this.crossSellProducts.length !== 0;
        },
        hasShippingStates() {
            return Object.keys(this.states.shipping).length !== 0;
        },
        hasShippingAddress() {
            return Object.keys(this.form.shipping).length === 3;
        },
        firstCountry() {
            return FleetCart.defaultCountry;
        },
    },

    created() {
        //Select first country on select field.
        this.changeShippingCountry(this.firstCountry);

        this.$nextTick(() => {
            if (store.state.cart.shippingMethodName) {
                this.changeShippingMethod(store.state.cart.shippingMethodName);
            } else {
                this.updateShippingMethod(this.firstShippingMethod);
            }

            this.fetchCrossSellProducts();
        });
    },

    methods: {
        updateCart(cartItem, qty) {
            this.loadingOrderSummary = true;

            axios
                .put(route("cart.items.update", { id: cartItem.id }), {
                    qty: qty || 1,
                })
                .then((response) => {
                    store.updateCart(response.data);
                })
                .catch(({ response }) => {
                    store.updateCart(response.data.cart);

                    this.$notify(response.data.message);
                })
                .finally(() => {
                    this.loadingOrderSummary = false;

                    if (this.hasShippingAddress) {
                        this.loadingOrderSummary = true;
                        this.getRates();
                    }
                });
        },

        removeCartItem(cartItem) {
            this.loadingOrderSummary = true;

            store.removeCartItem(cartItem);

            if (store.cartIsEmpty()) {
                this.crossSellProducts = [];
            }

            axios
                .delete(route("cart.items.destroy", { id: cartItem.id }))
                .then((response) => {
                    store.updateCart(response.data);
                })
                .catch((error) => {
                    this.$notify(error.response.data.message);
                })
                .finally(() => {
                    this.loadingOrderSummary = false;

                    if (! store.cartIsEmpty() && this.hasShippingAddress) {
                        this.loadingOrderSummary = true;
                        this.getRates();
                    }
                });
        },

        clearCart() {
            store.clearCart();

            if (store.cartIsEmpty()) {
                this.crossSellProducts = [];
            }

            axios
                .delete(route("cart.clear"))
                .then((response) => {
                    store.updateCart(response.data);
                })
                .catch((error) => {
                    this.$notify(error.response.data.message);
                });
        },

        changeShippingMethod(shippingMethodName) {
            this.shippingMethodName = shippingMethodName;
        },

        async fetchCrossSellProducts() {
            try {
                const response = await axios.get(
                    route("cart.cross_sell_products.index")
                );

                this.crossSellProducts = response.data;
            } catch (error) {
                this.$notify(error.response.data.message);
            }
        },


        changeShippingState(state) {
            this.$set(this.form.shipping, 'state', state);
        },

        changeShippingZip(zip) {
            this.$set(this.form.shipping, 'zip', zip.replace(/-|\s/g,''));
        },

        changeShippingCountry(country) {
            this.$set(this.form.shipping, 'country', country);

            this.fetchStates(country, (states) => {
                this.loadingOrderSummary = false;
                this.$set(this.states, 'shipping', states);
                this.$set(this.form.shipping, 'state', '');
            });
        },

        fetchStates(country, callback) {
            this.loadingOrderSummary = true;
            $.ajax({
                method: 'GET',
                url: route('countries.states.index', { code: country }),
            }).then(callback);
        },
    },
};
